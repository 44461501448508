<template>
  <div class="counter-list">
    <counterGroup
      :key="'days'"
      :item="days"
      :counterText="'Days'"
      :color="color"
    />
    <counterGroup
      :key="'hours'"
      :item="hours"
      :counterText="'Hours'"
      :color="color"
    />
    <counterGroup
      :key="'minutes'"
      :item="minutes"
      :counterText="'Minutes'"
      :color="color"
    />
    <counterGroup
      :key="'seconds'"
      :item="seconds"
      :counterText="'Seconds'"
      :color="color"
      :seconds="true"
    />
  </div>
</template>

<script>
import counterGroup from './counterGroup';

export default {
  name: 'Counter',
  components: {
    counterGroup,
  },
  props: {
    startDate: {
      type: [Date, String],
      default: new Date(),
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      diff: 0,
      interval: -1,
      now: Math.trunc((new Date()).getTime() / 1000),
    };
  },
  computed: {
    seconds() {
      return Math.trunc(this.diff) % 60;
    },
    secondOne() {
      if (this.seconds < 10) {
        return 0;
      }
      return +String(this.seconds).substr(0, 1);
    },
    secondTwo() {
      if (this.seconds < 10) {
        return this.seconds;
      }
      return +String(this.seconds).substr(1, 1);
    },
    minutes() {
      return Math.trunc(this.diff / 60) % 60;
    },
    hours() {
      return Math.trunc(this.diff / 60 / 60) % 24;
    },
    days() {
      return Math.trunc(this.diff / 60 / 60 / 24);
    },
  },
  watch: {
    now() {
      this.diff = this.date - this.now;
      if (this.diff <= 0) {
        this.diff = 0;
        clearInterval(this.interval);
      }
    },
    startDate(newVal) {
      this.date = Math.trunc((new Date(newVal)).getTime() / 1000);
      this.initClock();
    },
  },
  mounted() {
    this.date = Math.trunc((new Date(this.startDate)).getTime() / 1000);
    this.initClock();
  },
  methods: {
    initClock() {
      this.interval = setInterval(() => {
        this.now = Math.trunc((new Date()).getTime() / 1000);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.counter-list {
  display: flex;
  justify-content: center;
  color: #fff;
}
</style>
<!-- Created by zas on 05.03.2021  -->
