<template>
  <div class="counter-group">
    <div class="counter-group__wrapper">
      <counterItem
        :key="'itemOne'"
        :item="itemOne"
      />
      <counterItem
        :key="'itemTwo'"
        :item="itemTwo"
        :seconds="seconds"
      />
    </div>
    <div
      class="counter-group__text"
      :class="{'counter-group__text_mobile': isMobile}"
      :style="{
        color
      }"
    >
      {{ item > 1 ? counterText : counterText.slice(0, -1) }}
    </div>
  </div>
</template>

<script>
import counterItem from './counterItem';

export default {
  name: 'CounterGroup',
  components: {
    counterItem,
  },
  props: {
    item: {
      type: Number,
      default: 0,
    },
    counterText: {
      type: String,
      default: '',
    },
    seconds: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
  computed: {
    // viewMode () {
    //   return this.$store.getters.viewMode
    // },
    isMobile() {
      // return this.viewMode === MODE_MOBILE
      return false;
    },
    itemOne() {
      if (this.item < 10) {
        return 0;
      }
      return +String(this.item).substr(0, 1);
    },
    itemTwo() {
      if (this.item < 10) {
        return this.item;
      }
      return +String(this.item).substr(1, 1);
    },
  },

};
</script>

<style lang="scss" scoped>
.counter-group {

  &__wrapper {
    display: flex;
  }

  &__text {
    text-align: center;
    font-size: 1.444em;
    margin-top: -8px;

    @media (max-width: 767px) {
      font-size: 1.222em;
      margin-top: -15px;
    }
  }

}

</style>

<style lang="scss">
  .counter-group {

    & + & {
      margin-left: 0.75em;
    }

  }
</style>
<!-- Created by zas on 05.03.2021  -->
