<template>
  <div class="counter-wrapper">
    <div
      class="counter"
      :class="{'counter_mobile': isMobile}"
    >
      <span class="decor top"></span>
      <span class="decor bottom"></span>
      <span
        class="top"
        :class="topClass"
      >
        <template v-if="isFrom">
          <span>{{ item }}</span>
        </template>
        <template v-else>
          <span>{{ item + 1 > 9 ? 0 : item + 1 }}</span>
        </template>
        <span class="shadow"></span>
      </span>
      <span
        class="bottom"
        :class="isFrom ? 'from' : 'to'"
      >
        <template v-if="isFrom">
          <span>{{ item }}</span>
        </template>
        <template v-else>
          <span>{{ item + 1 > 9 ? 0 : item + 1 }}</span>
        </template>
        <span class="shadow"></span>
      </span>
      <span
        class="top"
        :class="bottomTopClass"
      >
        <template v-if="!isFrom">
          <span>{{ item }}</span>
        </template>
        <template v-else>
          <span>{{ item + 1 > 9 ? 0 : item + 1 }}</span>
        </template>
        <span class="shadow"></span>
      </span>
      <span
        class="bottom"
        :class="!isFrom ? 'from' : 'to'"
      >
        <template v-if="!isFrom">
          <span>{{ item }}</span>
        </template>
        <template v-else>
          <span>{{ item + 1 > 9 ? 0 : item + 1 }}</span>
        </template>
        <span class="shadow"></span>
      </span>
    </div>
    <div class="countdown-wrapper__text">
      {{ item > 1 ? counterText : counterText.slice(0, -1) }}
    </div>
  </div>
</template>

<script>
// import {MODE_MOBILE} from '@@@/store/constant/modeViewNames'

export default {
  name: 'CounterItem',
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return `0${value.toString()}`;
      }
      return value.toString();
    },
  },
  props: {
    item: {
      type: Number,
      default: 0,
    },
    counterText: {
      type: String,
      default: '',
    },
    seconds: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFrom: true,
      topClass: 'from',
      bottomTopClass: '',
    };
  },
  computed: {
    // viewMode () {
    //   return this.$store.getters.viewMode
    // },
    isMobile() {
      // return this.viewMode === MODE_MOBILE
      return false;
    },
  },
  watch: {
    item(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFrom = !this.isFrom;
        this.topClass = this.isFrom ? 'from' : 'to';
        this.bottomTopClass = !this.isFrom ? 'from' : 'to';
      }
      if (!this.seconds) {
        setTimeout(() => {
          if (this.topClass === 'to') {
            this.topClass = '';
          }
          if (this.bottomTopClass === 'to') {
            this.bottomTopClass = '';
          }
        }, 1000);
      } else {
        if (this.topClass === 'to') {
          this.topClass = '';
        }
        if (this.bottomTopClass === 'to') {
          this.bottomTopClass = '';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  width: 0.77em;
  height: 1.667em;
  margin-bottom: 0.106em;
  display:inline-flex;
  font-size: 4.125em;
  line-height: 1.7;
  perspective: 2em;
  position:relative;

  &_mobile {
    font-size: 3.608em;
    line-height: 1.18;

    &.counter > span {
      &.top span {
        left: 0;
        bottom: -0.277em;
      }

      &.bottom span {
        left: 0;
        top: -0.554em;
      }
    }

  }

  > span {
    display: block;
    width: 100%;
    height: 0.803em;
    position: absolute;
    overflow: hidden;
    background: #1a1927;
    text-align: center;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    border-radius: 4px!important;
    font-size: inherit;

    &.top {
      box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 3px 3px 0 0;

      span {
        position: relative;
        bottom: 0.06em;
        left: -0.015em;
        font-size: inherit;
      }

    }

    &.bottom {
      //z-index: 0;
      top: 0.831em;
      box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.2);
      border-radius:0 0 3px 3px;
      font-size: inherit;

      span {
        position: relative;
        top: -0.8787em;
        left: -0.015em;
        font-size: inherit;
      }

    }

    .shadow {
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0!important;
      overflow: hidden;
      z-index: 0;
      opacity: 0;
    }

  }

}

.counter > span.decor.top {
  box-shadow: 0 24px 43px -3px rgba(0, 0, 0, 0.45);
}
.counter > span.decor.bottom {
  box-shadow: 0px 0px 2.74957px rgba(0, 0, 0, 0.12), 0px 2.74957px 2.74957px rgba(0, 0, 0, 0.24);
}

.counter > span.from.bottom {
  z-index:1;
  transform-origin: 0% 0%;
  animation: from-flip 1s;
}

.counter > span.from.top {
  z-index: 1;
}

.counter > span.to.top {
  z-index: 2;
  transform-origin: 100% 100%;
  animation: to-flip 1s;
}
@keyframes from-flip {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes to-flip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.counter .top .shadow {
  background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
}
.counter .bottom .shadow {
  background: linear-gradient(rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
}
.counter .from.top .shadow { animation: fade-out 1s; }
.counter .to.bottom .shadow { animation: fade-in 1s; }
.hide .shadow { animation:none !important; }
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .counter > span span {

  }
}

.countdown-wrapper {

  &__text {
    text-align: center;
    font-size: 1.125em;
  }

}

</style>

<style lang="scss">

.counter-wrapper {

  & + & {
    margin-left: 2px;
  }

}

</style>

<!-- Created by zas on 05.03.2021  -->
